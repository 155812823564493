// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-programa-section-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/templates/programa-section.cas.js" /* webpackChunkName: "component---src-templates-programa-section-cas-js" */),
  "component---src-templates-programa-section-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/templates/programa-section.val.js" /* webpackChunkName: "component---src-templates-programa-section-val-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actua-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/actua.cas.js" /* webpackChunkName: "component---src-pages-actua-cas-js" */),
  "component---src-pages-actua-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/actua.val.js" /* webpackChunkName: "component---src-pages-actua-val-js" */),
  "component---src-pages-candidatura-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/candidatura.cas.js" /* webpackChunkName: "component---src-pages-candidatura-cas-js" */),
  "component---src-pages-candidatura-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/candidatura.val.js" /* webpackChunkName: "component---src-pages-candidatura-val-js" */),
  "component---src-pages-cartells-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/cartells.cas.js" /* webpackChunkName: "component---src-pages-cartells-cas-js" */),
  "component---src-pages-cartells-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/cartells.val.js" /* webpackChunkName: "component---src-pages-cartells-val-js" */),
  "component---src-pages-index-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/index.cas.js" /* webpackChunkName: "component---src-pages-index-cas-js" */),
  "component---src-pages-index-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/index.val.js" /* webpackChunkName: "component---src-pages-index-val-js" */),
  "component---src-pages-joan-baldovi-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/joan-baldovi.cas.js" /* webpackChunkName: "component---src-pages-joan-baldovi-cas-js" */),
  "component---src-pages-joan-baldovi-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/joan-baldovi.val.js" /* webpackChunkName: "component---src-pages-joan-baldovi-val-js" */),
  "component---src-pages-jordi-sebastia-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/jordi-sebastia.cas.js" /* webpackChunkName: "component---src-pages-jordi-sebastia-cas-js" */),
  "component---src-pages-jordi-sebastia-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/jordi-sebastia.val.js" /* webpackChunkName: "component---src-pages-jordi-sebastia-val-js" */),
  "component---src-pages-monica-oltra-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/monica-oltra.cas.js" /* webpackChunkName: "component---src-pages-monica-oltra-cas-js" */),
  "component---src-pages-monica-oltra-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/monica-oltra.val.js" /* webpackChunkName: "component---src-pages-monica-oltra-val-js" */),
  "component---src-pages-programa-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/programa.cas.js" /* webpackChunkName: "component---src-pages-programa-cas-js" */),
  "component---src-pages-programa-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/programa.val.js" /* webpackChunkName: "component---src-pages-programa-val-js" */),
  "component---src-pages-volem-saber-cas-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/volem-saber.cas.js" /* webpackChunkName: "component---src-pages-volem-saber-cas-js" */),
  "component---src-pages-volem-saber-val-js": () => import("/codebuild/output/src845600293/src/imparables/src/pages/volem-saber.val.js" /* webpackChunkName: "component---src-pages-volem-saber-val-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src845600293/src/imparables/.cache/data.json")

